export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const BACKEND_SERVER_LOCAL_URL = process.env.REACT_APP_BACKEND_LOCAL_URL;
export const BACKEND_SERVER_STAGE_URL = process.env.REACT_APP_BACKEND_STAGE_URL;
export const BACKEND_SERVER_PROD_URL = process.env.REACT_APP_BACKEND_PROD_URL;
export const SERVER_TYPE = process.env.REACT_APP_NODE_ENV;
export let BACKEND_SERVER_URL =
  SERVER_TYPE === "local"
    ? BACKEND_SERVER_LOCAL_URL
    : SERVER_TYPE === "development"
    ? BACKEND_SERVER_STAGE_URL
    : SERVER_TYPE === "production"
    ? BACKEND_SERVER_PROD_URL
    : "";