import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
// import { apiSignIn, apiSignOut } from 'services/AuthService'
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { BACKEND_SERVER_URL } from "constants/api.constant";
import api from "views/api/api";
import { clearRoleDetails, setRoleDetails } from "store/role/roleSlice";
import { delay } from "lodash";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async ({ email, password }) => {
    try {
      const formData = {
        email: `${email}`,
        password: `${password}`,
      };

      const resp = await api.post("/login", formData);
      const respo = await resp.data;

      if (respo.data && respo.data.token) {
        const token = respo.data.token;

        dispatch(setToken(token));

        dispatch(onSignInSuccess(token));

        if (respo.data.record) {
          const userData = respo.data.record;
          dispatch(
            setUser({
              _id: userData.id ? userData.id : "",
              userName: userData.name ? userData.name : "user",
              authority: userData.role ? userData.role.roleName : "guest",
              authorityId: userData.role ? userData.role._id : "guest",
              email: userData.email ? userData.email : "test@gmail.com",
              onLeave: userData.onLeave ? userData.onLeave : false,
            })
          );
          dispatch(setRoleDetails(userData?.roleDetails));
          window.location.reload();
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: "login done",
          data: respo.data,
        };
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    window.location.reload();
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(clearRoleDetails());
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
