import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import "./locales";
import "./App.css";
import { ThemeProvider } from "context/ThemeContext";

function App() {
  useEffect(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        document.body.style.transition = "zoom 0.8s ease";
        document.body.style.zoom = "100%";

        let windowScreenWidth = window.screen.width;
        let windowScreenHeight = window.screen.height;
        let sideNav = document.getElementsByClassName(
          "side-nav-zoom-toggle-custom-class"
        )[0];
        let logo = document.getElementsByClassName("adjust-logo-custom")[0];

        if (windowScreenWidth === 1536) {
          document.body.style.transition = "zoom 0.5s ease";
          document.body.style.zoom = "80%";
          if(sideNav && logo){
            sideNav.setAttribute("id", "side-nav-zoom");
            logo.setAttribute("id", "adjust-logo-custom-padding");
          }
        } else {
          document.body.style.transition = "zoom 0.5s ease";
          document.body.style.zoom = "100%";
          if(sideNav && logo){
            sideNav.setAttribute("id", "");
            logo.setAttribute("id", "");
          }
        }

        if (windowScreenHeight === 726) {
          if(sideNav && logo){
            logo.setAttribute("id", "adjust-logo-custom-padding-height");
          }
        } else {
          if(sideNav && logo){
            logo.setAttribute("id", "");
          }
        }
      }, 1000);
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <ThemeProvider>
            <Theme>
              <Layout />
            </Theme>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
