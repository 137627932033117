// ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';
import deepParseJson from 'utils/deepParseJson';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [mode, setMode] = useState('dark'); // default mode if no mode is found in local storage

  // Function to update the mode and also save it to local storage
  const updateMode = (newMode) => {
    setMode(newMode);
    localStorage.setItem('admin', JSON.stringify({ theme: { mode: newMode } }));
  };

  // Load the mode from local storage on app load
  useEffect(() => {
    const themeData = localStorage.getItem('admin');
    const parsedData = deepParseJson(themeData);
    const mode = parsedData?.theme?.mode;
    if (mode) {
      setMode(mode);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ mode, updateMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
