import { createSlice } from "@reduxjs/toolkit";

const roleSlice = createSlice({
  name: "role",
  initialState: null,
  reducers: {
    setRoleDetails: (state, action) => action.payload,
    clearRoleDetails: (state, action) => null,
  },
});

export const { setRoleDetails, clearRoleDetails } = roleSlice.actions;
export default roleSlice.reducer;
