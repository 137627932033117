import { THEME_ENUM } from 'constants/theme.constant'

export const themeConfig = {
  "themeColor": "blue",
  "direction": "ltr",
  "mode": "dark",
  "locale": "en",
  "primaryColorLevel": 900,
  "cardBordered": true,
  "panelExpand": false,
  "controlSize": "md",
  "navMode": "dark",
  "layout": {
    "type": "classic",
    "sideNavCollapse": false
  }
}


